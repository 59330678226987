import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tour: Conversational Analytics`}</h1>
    <h2>{`Phrase Clusters`}</h2>
    <p>{`Dashbot Phrase Clustering groups similar messages together, whether you are using an NLP engine or not.`}</p>
    <p>{`Identify mishandled and unhandled Intents to improve NLP response effectiveness and increase user satisfaction.`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/Gn86aDSWD9k?feature=oembed" width="1200" />
    <h2>{`Unhandled Report`}</h2>
    <p>{`With Dashbot’s Phrase Clustering and Unhandled Report, you can identify where the NLP is breaking with unhandled/fallback Intents so that you can improve the NLP response effectiveness.`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/e4IGl7d-49Q?feature=oembed" width="1200" />
    <h2>{`Message Funnels`}</h2>
    <p>{`Message and Interaction Funnels enable you to see where your chatbot or skill may be breaking down as well as opportunities for new use cases to support.`}</p>
    <p>{`Simply select any message or interaction in or out from your bot or skill and see what happened before and after, and continue to traverse the flows in either direction.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03125015/message-funnel.png",
        "alt": null
      }}></img></p>
    <h2>{`NLP Intents `}{`&`}{` Entities`}</h2>
    <p>{`How effective are your NLP and Intents?`}</p>
    <p>{`With chatbots and voice skills, users can say or write whatever they want.`}</p>
    <p>{`Our Intent funnels and reports help identify where the Intents may be breaking down or even new use-cases you may not have thought of. You can also see the utterances and messages that mapped to the Intents, as well as all the entities.`}</p>
    <h3>{`Intent Reports`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/02115455/intent-report-1024x613.png",
        "alt": null
      }}></img></p>
    <h3>{`Intent Funnels`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03132512/intent-funnel.png",
        "alt": null
      }}></img></p>
    <h3>{`Intent Mappings`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/02115801/intent-mappings-1024x591.png",
        "alt": null
      }}></img></p>
    <h3>{`Intent Entities`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/02120549/intent-entities-1200-1024x695.png",
        "alt": null
      }}></img></p>
    <h2>{`Transcripts`}</h2>
    <p>{`In addition to top messages, utterances, and intents, we reconstruct all the session transcripts – `}<strong parentName="p">{`including live sessions which can be viewed in real time`}</strong>{`!`}</p>
    <p>{`We support the full richness and multi-modal nature of platforms. Messages in and out can be rich media: images, animated gifs, audio, video, GPS coordinates, cards, lists, and more.`}</p>
    <p>{`If you want to know what users think of your chat bot or voice skill, they’ll tell you — you can see it right in the transcripts.`}</p>
    <h3>{`Chatbot Transcripts`}</h3>
    <p>{`The example below is from a Facebook chatbot. Dashbot supports the full richness of the platform including cards, buttons, quick replies, images, location maps, audio, video, and more.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03145633/transcript-fb.png",
        "alt": null
      }}></img></p>
    <h3>{`Voice Skill Transcripts`}</h3>
    <p>{`The example transcript below is from a Google Action. Dashbot supports the multimodal nature of Alexa and Google Assistant.`}</p>
    <p>{`In addition to the utterances, Dashbot shows the underlying Intent, Action, and Context from the NLP for the utterance.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03151211/transcript-google-780x1024.png",
        "alt": null
      }}></img></p>
    <h3>{`Utterance NLP`}</h3>
    <p>{`For chatbots and voice skills that make use of NLP, Dashbot shows the underlying Intent, Action, and Context associated with the utterance or message.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03152410/nlp2.png",
        "alt": null
      }}></img></p>
    <h2>{`Transcript Search`}</h2>
    <p>{`Dashbot provides full text search of transcripts including filtering and sorting capabilities.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/03113350/transcript-search.gif",
        "alt": null
      }}></img></p>
    <h2>{`Anomalous Sessions`}</h2>
    <p>{`Dashbot can detect anomalous sessions – outliers from typical conversions that may warrant further review.`}</p>
    <p>{`Quickly identify outliers and dive deeper into the conversations to see where users may be having difficulty.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/03111930/outlier-transcripts-1024x486.png",
        "alt": null
      }}></img></p>
    <h2>{`Sentiment Analysis`}</h2>
    <p>{`The sentiment analysis provides insights into the interactions users are having – whether they are positive or negative. Dashbot analyzes sentiment at the individual message/utterance level, transcript level, and time intervals as in the daily report below.`}</p>
    <h2>{`Word Clouds`}</h2>
    <p>{`Word clouds help show the most common messages and utterances in your chatbot or voice skill.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03141402/wordclouds.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      